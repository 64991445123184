import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Field, reduxForm } from 'redux-form';
import { scrollToAnchor } from 'src/utils/helpers';
import {
    asyncCheckConflict,
    countryMenuItems,
    genderMenuItems,
    renderImageSelector,
    renderInput,
    renderSelectField,
} from 'src/components/misc/redux-form-helpers';
import { ImageCropperTypes } from 'src/components/images/image-cropper';
import { Entities, TOMCAT_URL } from 'src/common/index';
import { isBlank, isUrl } from 'src/components/misc/validations';

class DriverEditForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const { handleSubmit, t } = this.props;

        return (
            <form className="form-horizontal" role="form" onSubmit={handleSubmit}>
                <div>
                    <div className="row">
                        <div className="col-md-4">
                            <Field
                                name="avatar"
                                t={t}
                                component={renderImageSelector}
                                type={ImageCropperTypes.DRIVER}
                            />
                        </div>
                        <div id="topSection" className="col-md-8">
                            <Field
                                name="email"
                                label={`${t('contact_data.user_email')} *`}
                                component={renderInput}
                                type="email"
                            />
                            <Field
                                name="contactData.gender"
                                label={t('contact_data.gender')}
                                component={renderSelectField}>
                                {genderMenuItems(t)}
                            </Field>
                            <Field
                                name="contactData.firstName"
                                label={`${t('contact_data.first_name')} *`}
                                component={renderInput}
                            />
                            <Field
                                name="contactData.lastName"
                                label={t('contact_data.last_name')}
                                component={renderInput}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Field
                                name="contactData.country"
                                label={t('contact_data.country')}
                                component={renderSelectField}>
                                {countryMenuItems()}
                            </Field>
                            <Field
                                name="contactData.postCode"
                                label={t('contact_data.post_code')}
                                hintText={t('contact_data.post_code_hint')}
                                component={renderInput}
                            />
                            <Field name="contactData.city" label={t('contact_data.city')} component={renderInput} />
                            <Field name="contactData.street" label={t('contact_data.street')} component={renderInput} />
                        </div>
                        <div className="col-md-6">
                            <Field
                                name="contactData.phone"
                                label={t('contact_data.tel_number')}
                                component={renderInput}
                            />
                            <Field name="contactData.fax" label={t('contact_data.fax')} component={renderInput} />
                            <Field
                                name="contactData.homepage"
                                label={t('contact_data.homepage')}
                                component={renderInput}
                            />
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {
        contactData: {
            firstName: undefined,
        },
    };

    if (isBlank(values.email)) errors.email = t('error_missing.fill_in_username');

    if (!values.contactData || (values.contactData && isBlank(values.contactData.firstName)))
        errors.contactData.firstName = t('error_missing.fill_in_first_name');

    if (values.contactData && !isUrl(values.contactData.homepage))
        errors.contactData = { homepage: t('error_missing.fill_in_homepage') };
    return errors;
};

const asyncValidate = (values, dispatch, props) => {
    const { t } = props;
    const accountId = values.id || -1;
    return asyncCheckConflict(
        props.t,
        `${TOMCAT_URL}api/${Entities.ACCOUNT.repository}/checkDuplicateEmail?email=${encodeURIComponent(
            values.email,
        )}&id=${accountId}`,
        'email',
        t('error_hint.username_already_exists'),
    );
};

const onSubmitFail = errors => {
    if (errors.email || errors.contactData) scrollToAnchor('topSection');
};

export default reduxForm({
    form: 'driverEdit',
    onSubmitFail,
    validate,
    asyncValidate,
    asyncBlurFields: ['email', 'contactData.firstName', 'contactData.lastName'],
})(DriverEditForm);
