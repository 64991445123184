import {
    Entities,
    fetchAll,
    fetchByCompanyId,
    fetchById,
    getEntities,
    getUpdatedEntity,
    patch,
    save,
    TOMCAT_URL,
} from 'src/common/index';
import _ from 'lodash';
import CircularProgress from 'material-ui/CircularProgress';
import moment from 'moment';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import BusEditForm from 'src/components/buses/bus-edit-form';
import ErrorMessage from 'src/components/misc/error-message';
import { getOwnCompany } from 'src/selectors/bus-companies';
import { getActiveDrivers } from 'src/selectors/drivers';
import { ReleaseMode } from 'src/utils/constants';
import { setSliderIndex } from 'src/actions/form';

class BusEditModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { isCreate, id, selectedCompany, duplicate } = this.props;

        if (!isCreate || duplicate) this.props.fetchById(id, Entities.BUS, selectedCompany);

        this.props.fetchAll(Entities.BUS_BRAND);
        this.props.fetchAll(Entities.BUS_ENGINE);
        this.props.fetchAll(Entities.BUS_EQUIPMENT);
        this.props.fetchByCompanyId(Entities.BUS_CATEGORY, selectedCompany);
        this.props.fetchByCompanyId(Entities.CALCULATION, selectedCompany);
        this.props.fetchByCompanyId(Entities.DRIVER, selectedCompany);
    }

    componentWillReceiveProps(nextProps) {
        const { brands } = nextProps;

        if (brands.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return !this.childForm.pristine;
    }

    render() {
        const {
            bus,
            duplicate,
            isCreate,
            brands,
            categories,
            engines,
            calculations,
            equipments,
            drivers,
            busCompany,
            selectedCompany,
            t,
        } = this.props;

        const finishFetching =
            this.state.startedFetching &&
            !brands.isFetching &&
            !categories.isFetching &&
            !engines.isFetching &&
            !calculations.isFetching &&
            !equipments.isFetching &&
            !drivers.isFetching &&
            !bus.isFetching;

        if (finishFetching) {
            if (bus.error) return <ErrorMessage object={bus} />;
            else {
                let initialValues;
                let stars;

                // if form is used to edit or duplicate a bus -> load initial values
                if ((!isCreate && bus.content) || (isCreate && duplicate && bus.content)) {
                    stars = bus.stars;

                    const busEquipments = equipments.items
                        .filter(equipment => bus.content.equipments.includes(equipment.id))
                        .map(equipment => equipment._links.self.href);

                    let regularDriver, brand, category, engine, calculation;
                    if (bus.content.regularDriver)
                        regularDriver = drivers.items.find(driver => driver.id === bus.content.regularDriver);
                    if (bus.content.brand) brand = brands.items.find(brand => brand.id === bus.content.brand);
                    if (bus.content.category)
                        category = categories.items.find(category => category.id === bus.content.category);
                    if (bus.content.engine) engine = engines.items.find(engine => engine.id === bus.content.engine);
                    if (bus.content.calculation)
                        calculation = calculations.items.find(
                            calculation => calculation.id === bus.content.calculation,
                        );

                    initialValues = {
                        id: duplicate ? undefined : bus.content.id,
                        name: duplicate ? '' : bus.content.name,
                        displayName: duplicate ? '' : bus.content.displayName,
                        vin: bus.content.vin,
                        active: bus.content.expired ? false : bus.content.active,
                        virtual: bus.content.virtual,
                        avatar: bus.content.avatar,
                        plateNumber: bus.content.plateNumber,
                        type: bus.content.type,
                        releaseMode: bus.content.releaseMode,
                        stars: bus.content.stars,
                        seatsFacingFront: bus.content.seatsFacingFront || 0,
                        seatsFacingBack: bus.content.seatsFacingBack || 0,
                        seatsForGuides: bus.content.seatsForGuides || 0,
                        registrationDate: moment(bus.content.registrationDate).format('DD.MM.YYYY'),
                        company: `${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/${selectedCompany}`,
                        brand: brand ? brand._links.self.href : undefined,
                        category: category ? category._links.self.href : undefined,
                        engine: engine ? engine._links.self.href : undefined,
                        calculation: calculation ? calculation._links.self.href : undefined,
                        regularDriver: regularDriver ? regularDriver._links.self.href : undefined,
                        equipments: busEquipments,
                        homeBase: bus.content.homeBase,
                        documents: bus.content.documents,
                        description: bus.content.description,
                        order: bus.content.order,
                        furtherBases: bus.content.furtherBases,
                        busHeightInCm: bus.content.busHeightInCm,
                        busLengthInCm: bus.content.busLengthInCm,
                        numberOfAxles: bus.content.numberOfAxles,
                        averageFuelConsumption: bus.content.averageFuelConsumption,
                        totalPermittedWeightInKg: bus.content.totalPermittedWeightInKg,
                    };
                } else {
                    initialValues = {
                        brand: brands.items[_.findIndex(brands.items)]._links.self.href || '',
                        category: categories.items[_.findIndex(categories.items)]._links.self.href || '',
                        engine: engines.items[_.findIndex(engines.items)]._links.self.href || '',
                        calculation: calculations.items[_.findIndex(calculations.items)]._links.self.href || '',
                        active: true,
                        virtual: false,
                        stars: undefined,
                        seatsFacingFront: 0,
                        seatsFacingBack: 0,
                        seatsForGuides: 0,
                        equipments: [],
                        regularDriver: undefined,
                        releaseMode: ReleaseMode.BLOCKED,
                        homeBase: busCompany.entity.location,
                        company: `${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/${selectedCompany}`,
                        documents: [],
                        registrationDate: undefined,
                        order: 0,
                        furtherBases: [],
                    };
                }

                let formatAllEquipments = [];
                equipments.items.forEach(equipment => formatAllEquipments.push(equipment));

                return (
                    <BusEditForm
                        t={t}
                        initialValues={initialValues}
                        isCreate={isCreate}
                        onSubmit={this.handleSubmit}
                        bus={bus.content}
                        busCompany={busCompany}
                        brands={brands}
                        engines={engines}
                        calculations={calculations}
                        categories={categories}
                        drivers={drivers}
                        allEquipments={formatAllEquipments}
                        stars={stars}
                        selectedCompany={selectedCompany}
                        setSliderIndex={this.props.setSliderIndex}
                        selectedSliderIndex={this.props.selectedSliderIndex}
                        ref={ref => (this.childForm = ref)}
                    />
                );
            }
        } else return <CircularProgress />;
    }

    handleSubmit(data) {
        const { selectedCompany, handleClose, isCreate } = this.props;

        // format registration date for backend
        data.registrationDate = moment(data.registrationDate, 'DD.MM.YYYY').format('YYYY-MM-DD');

        if (isCreate) this.props.save(data, Entities.BUS, selectedCompany);
        else this.props.patch(data, Entities.BUS, selectedCompany);

        handleClose();
    }
}

const mapStateToProps = state => {
    return {
        bus: getUpdatedEntity(state, Entities.BUS, state.selectedCompany),
        brands: getEntities(state, Entities.BUS_BRAND),
        categories: getEntities(state, Entities.BUS_CATEGORY, state.selectedCompany),
        engines: getEntities(state, Entities.BUS_ENGINE),
        equipments: getEntities(state, Entities.BUS_EQUIPMENT),
        drivers: getActiveDrivers(state),
        calculations: getEntities(state, Entities.CALCULATION, state.selectedCompany),
        busCompany: getOwnCompany(state),
        selectedCompany: state.selectedCompany,
        selectedSliderIndex: state.selectedSliderIndex,
    };
};

export default connect(
    mapStateToProps,
    {
        fetchById,
        fetchAll,
        fetchByCompanyId,
        save,
        patch,
        setSliderIndex,
    },
    null,
    { withRef: true },
)(BusEditModal);
