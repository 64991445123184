import { connect } from 'react-redux';
import { deleteById, Entities, fetchByCompanyId, getEntities, patch } from 'src/common/index';
import { fetchOwnBusCompany } from 'src/actions/bus-companies';
import { getOwnCompany } from 'src/selectors/bus-companies';
import TaskRatingAdministration from 'src/components/administration/task-ratings/task-rating-administration';
import { fetchReviewsByCompany, fetchReviewsByFilter, patchOperatorAnswer } from 'src/actions/ratings';

const mapStateToProps = state => {
    return {
        selectedCompany: state.selectedCompany,
        reviews: getEntities(state, Entities.REVIEW, state.selectedCompany),
        busCompany: getOwnCompany(state),
        filter: state.filter,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchReviewsByFilter: (selectedCompany, filter) => {
            dispatch(fetchReviewsByFilter(selectedCompany, filter));
        },
        fetchReviewsByCompany: selectedCompany => {
            dispatch(fetchReviewsByCompany(selectedCompany));
        },
        patchOperatorAnswer: (selectedCompany, review) => {
            dispatch(patchOperatorAnswer(selectedCompany, review));
        },
    };
};

const TaskRatingAdministrationContainer = connect(mapStateToProps, mapDispatchToProps)(TaskRatingAdministration);

export default TaskRatingAdministrationContainer;
