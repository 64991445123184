import { CompanyOrigin } from 'src/common/index';
import { TFunction } from 'i18next';
import Badge from 'material-ui/Badge';
import OperatingToolIcon from 'material-ui/svg-icons/action/date-range';
import AgeRatingIcon from 'material-ui/svg-icons/action/date-range';
import IntegrationIcon from 'material-ui/svg-icons/hardware/desktop-windows';
import React from 'react';
import { amber700, blue700, red700 } from 'material-ui/styles/colors';
import CompanyIcon from 'material-ui/svg-icons/communication/business';
import AccountIcon from 'material-ui/svg-icons/action/supervisor-account';
import UserIcon from 'material-ui/svg-icons/action/account-circle';
import BrandIcon from 'material-ui/svg-icons/action/list';
import EquipmentIcon from 'material-ui/svg-icons/places/business-center';
import StarRatingIcon from 'material-ui/svg-icons/action/stars';
import EngineIcon from 'material-ui/svg-icons/av/av-timer';
import EmptyStarIcon from 'material-ui/svg-icons/toggle/star-border';
import FullStarIcon from 'material-ui/svg-icons/toggle/star';
import BookedTaskIcon from 'material-ui/svg-icons/action/description';
import DownloadIcon from 'material-ui/svg-icons/file/file-download';
import ReviewCategoryIcon from 'material-ui/svg-icons/action/loyalty';
import SettingsIcon from 'material-ui/svg-icons/action/settings';
import EuroIcon from 'material-ui/svg-icons/action/euro-symbol';
import CouponIcon from 'material-ui/svg-icons/action/euro-symbol';
import HelpIcon from 'material-ui/svg-icons/action/help';
import CustomerGroupIcon from 'material-ui/svg-icons/social/group';
import CancelIcon from 'material-ui/svg-icons/navigation/cancel';
import MapsIcon from 'material-ui/svg-icons/maps/place';
import InvoiceIcon from 'material-ui/svg-icons/action/receipt';
import SchoolIcon from 'material-ui/svg-icons/social/school';
import PersonAddIcon from 'material-ui/svg-icons/social/person-add';
import LoadingIcon from 'material-ui/svg-icons/action/autorenew';
import FleetIcon from 'material-ui/svg-icons/maps/directions-bus';
import BusIcon from 'material-ui/svg-icons/maps/directions-bus';
import TextFormatIcon from 'material-ui/svg-icons/content/text-format';
import StatisticsIcon from 'material-ui/svg-icons/editor/insert-chart';
import ActionClass from 'material-ui/svg-icons/action/class';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { fetchAdminNews } from 'src/actions/admin-news';
import Navigation, { ComponentRoute, ComponentRoutes } from 'src/components/nav/Navigation';
import { isLoggedInAsEvoAdmin } from 'src/selectors/accounts';
import { StoreState } from 'src/types';
import composeModalActions from 'src/utils/modal-action-wrapper';

/**
 * Compose correct admin trip path
 */
const composeAdminPath = (route: string) => `/admin/${route}`;
const composeAdminCompanyPath = (route: string) => composeAdminPath(`company?filter=${route}`);
const composeAdminBookingPath = (route: string) => composeAdminPath(`booked-tasks?filter=${route}`);
const composeAdminIntegrationPath = (route: string) => composeAdminPath(`integrations/${route}`);
const composeAdminStatisticsPath = (route: string) => composeAdminPath(`statistics/${route}`);
const composeAdminReviewRatingPath = (route: string) => composeAdminPath(`rating-reviews?filter=${route}`);

const badge = (label: string, value: React.ReactNode): React.ReactNode => (
    <Badge
        className="admin-notification-badge"
        badgeContent={value}
        primary={true}
        badgeStyle={{ right: -35, top: -2 }}
        style={{ padding: 0 }}>
        {label}
    </Badge>
);

const getAdminBadgePrimaryText = (label: string, adminNews: any) => {
    if (
        !adminNews.registeredCompaniesWithoutInitialActivation ||
        adminNews.registeredCompaniesWithoutInitialActivation < 1
    )
        return label;

    return badge(label, adminNews.registeredCompaniesWithoutInitialActivation);
};

/**
 * Defines all admin menu items. The order matters
 */
const adminRoutes = (adminNews: any, t: TFunction): ComponentRoutes => [
    {
        path: composeAdminPath('fleets'),
        icon: <FleetIcon color={blue700} />,
        primaryText: t('fleets.fleets'),
    },
    {
        path: composeAdminPath('company'),
        icon: <CompanyIcon color={blue700} />,
        primaryText: getAdminBadgePrimaryText(t('common_phrases.company'), adminNews),
        children: [
            {
                path: composeAdminCompanyPath(CompanyOrigin.ONLINE_REGISTRATION),
                icon: <BookedTaskIcon color={blue700} />,
                primaryText: getAdminBadgePrimaryText(t('admin_company.registered_online'), adminNews),
            },
            {
                path: composeAdminCompanyPath(CompanyOrigin.DAIMLER_IMPORT),
                icon: <DownloadIcon color={blue700} />,
                primaryText: t('admin_company.daimler_import'),
            },
            {
                path: composeAdminCompanyPath(CompanyOrigin.ADMIN_CREATE),
                icon: <UserIcon color={blue700} />,
                primaryText: t('admin_company.created_by_admin'),
            },
        ],
    },
    {
        path: composeAdminPath('accounts'),
        icon: <AccountIcon color={blue700} />,
        primaryText: t('header_account'),
    },
    {
        path: composeAdminPath('booked-tasks'),
        icon: <BookedTaskIcon color={blue700} />,
        primaryText: t('booked_tasks.booked_tasks'),
        children: [
            {
                path: composeAdminBookingPath('withReview'),
                icon: <FullStarIcon color={amber700} />,
                primaryText: t('booked_tasks.with_rating'),
            },
            {
                path: composeAdminBookingPath('withoutReview'),
                icon: <EmptyStarIcon color={amber700} />,
                primaryText: t('booked_tasks.without_rating'),
            },
            {
                path: composeAdminBookingPath('cancelled'),
                icon: <CancelIcon color={red700} />,
                primaryText: t('booked_tasks.cancelled_tasks'),
            },
            {
                path: composeAdminBookingPath('notReceived'),
                icon: <LoadingIcon color={amber700} />,
                primaryText: t('booked_tasks.not_yet_received'),
            },
            {
                path: composeAdminBookingPath('paymentPending'),
                icon: <EuroIcon color={red700} />,
                primaryText: t('tasks.pending_payment'),
            },
        ],
    },
    {
        path: composeAdminPath('customers'),
        icon: <AccountIcon color={blue700} />,
        primaryText: t('customer_details.customer'),
    },
    {
        path: composeAdminPath('customer-groups'),
        icon: <CustomerGroupIcon color={blue700} />,
        primaryText: t('customer_group.groups'),
    },
    {
        path: composeAdminPath(''),
        icon: <BusIcon color={blue700} />,
        primaryText: t('common_phrases.vehicles'),
        disabled: true,
        children: [
            {
                path: composeAdminPath('brands'),
                icon: <BrandIcon color={blue700} />,
                primaryText: t('brands.car_brands'),
            },
            {
                path: composeAdminPath('equipments'),
                icon: <EquipmentIcon color={blue700} />,
                primaryText: t('equipment.equipment'),
            },
            {
                path: composeAdminPath('age-rating'),
                icon: <AgeRatingIcon color={blue700} />,
                primaryText: t('age_rating.age_rating'),
            },
            {
                path: composeAdminPath('star-rating'),
                icon: <StarRatingIcon color={blue700} />,
                primaryText: t('star_rating.rating'),
            },
            {
                path: composeAdminPath('engines'),
                icon: <EngineIcon color={blue700} />,
                primaryText: t('engines.euronorm_other'),
            },
        ],
    },
    {
        path: composeAdminPath('invoices'),
        icon: <InvoiceIcon color={blue700} />,
        primaryText: t('invoice.invoices'),
    },
    {
        path: composeAdminPath('coupons'),
        icon: <CouponIcon color={blue700} />,
        primaryText: t('coupons.coupons'),
    },
    {
        path: composeAdminPath(''),
        icon: <SchoolIcon color={blue700} />,
        primaryText: t('organizations'),
        disabled: true,
        children: [
            {
                path: composeAdminPath('schools'),
                icon: <SchoolIcon color={blue700} />,
                primaryText: t('schools.schools'),
            },
            {
                path: composeAdminPath('associations'),
                icon: <ActionClass color={blue700} />,
                primaryText: t('associations.associations'),
            },
        ],
    },
    {
        path: composeAdminPath('referrals'),
        icon: <PersonAddIcon color={blue700} />,
        primaryText: t('referrals.referrals'),
    },
    {
        path: composeAdminPath('integrations'),
        icon: <IntegrationIcon color={blue700} />,
        primaryText: t('booked_tasks.integrations'),
        disabled: true,
        children: [
            {
                path: composeAdminIntegrationPath('booking'),
                icon: <CustomerGroupIcon color={blue700} />,
                primaryText: t('booking'),
            },
            {
                path: composeAdminIntegrationPath('operatingtool'),
                icon: <OperatingToolIcon color={blue700} />,
                primaryText: t('header_operating_tool'),
            },
        ],
    },
    {
        path: composeAdminPath('statistics'),
        icon: <StatisticsIcon color={blue700} />,
        primaryText: t('statistics'),
        disabled: true,
        children: [
            {
                path: composeAdminStatisticsPath('bus-locations'),
                icon: <MapsIcon color={blue700} />,
                primaryText: t('bus.map'),
            },
            {
                path: composeAdminStatisticsPath('search-requests'),
                icon: <OperatingToolIcon color={blue700} />,
                primaryText: t('search_requests.title'),
            },
        ],
    },
    {
        path: composeAdminPath('review-categories'),
        icon: <ReviewCategoryIcon color={blue700} />,
        primaryText: t('review_categories.categories'),
    },
    {
        path: composeAdminPath(''),
        icon: <StarRatingIcon color={blue700} />,
        primaryText: t('administration.task_ratings.ratings'),
        disabled: true,
        children: [
            {
                path: composeAdminReviewRatingPath('commentsRequireReview'),
                icon: <LoadingIcon color={blue700} />,
                primaryText: t('administration.task_ratings.comment_approval_required'),
            },
            {
                path: composeAdminReviewRatingPath('answersRequireReview'),
                icon: <LoadingIcon color={blue700} />,
                primaryText: t('administration.task_ratings.answer_approval_required'),
            },
        ],
    },
    {
        path: composeAdminPath('settings'),
        icon: <SettingsIcon color={blue700} />,
        disabled: true,
        primaryText: t('global_settings.settings'),
        children: [
            {
                path: composeAdminPath('main-company-branch-data'),
                icon: <MapsIcon color={blue700} />,
                primaryText: t('main_company_branch_data.title'),
            },
            {
                path: composeAdminPath('weighting'),
                icon: <BusIcon color={blue700} />,
                primaryText: t('global_settings.settings_menu'),
            },
            {
                path: composeAdminPath('global-templates'),
                icon: <TextFormatIcon color={blue700} />,
                primaryText: t('global_settings.text_blocks'),
            },
        ],
    },
    {
        path: composeAdminPath('help-administration'),
        icon: <HelpIcon color={blue700} />,
        primaryText: t('help.help'),
        children: [
            {
                path: composeAdminPath('help-category-administration'),
                icon: <HelpIcon color={blue700} />,
                primaryText: t('common_phrases.category'),
            },
            {
                path: composeAdminPath('help-entry-administration'),
                icon: <HelpIcon color={blue700} />,
                primaryText: t('help.support'),
            },
        ],
    },
];

/**
 * Renders the admins administration sidebar on the left hand side
 * and the related content on the right
 */
class AdminNavigation extends React.Component<any, {}> {
    componentDidMount() {
        this.props.fetchAdminNews();
    }

    public render() {
        const { adminNews, t } = this.props;

        let routes = adminRoutes(adminNews, t);
        if (isLoggedInAsEvoAdmin())
            routes = routes
                .filter((route: ComponentRoute) =>
                    [composeAdminPath('fleets'), composeAdminPath('company'), composeAdminPath('accounts')].includes(
                        route.path,
                    ),
                )
                .concat({
                    path: composeAdminIntegrationPath('operatingtool'),
                    icon: <OperatingToolIcon color={blue700} />,
                    primaryText: t('data_interface'),
                });

        return (
            <div>
                <div id="administration" className="col-md-2 sidebar-listview">
                    <Navigation routes={routes} />
                </div>

                <div className="col-md-10">{this.props.children}</div>
            </div>
        );
    }
}

const mapStateToProps = (state: StoreState) => {
    return {
        adminNews: state.adminNews,
    };
};

export default withTranslation()(connect(mapStateToProps, { fetchAdminNews })(composeModalActions(AdminNavigation)));
