import autoBind from 'react-autobind/src/autoBind';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Entities, fetchById, getUpdatedEntity, patch } from 'src/common';
import { setSliderIndex } from 'src/actions/form';
import RatingAnswerForm from 'src/components/administration/task-ratings/components/rating-answer-form';
import ErrorMessage from 'src/components/misc/error-message';
import CircularProgress from 'material-ui/CircularProgress';
import moment from 'moment';
import { patchOperatorAnswer } from 'src/actions/ratings';

class RatingAnswerModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { id, selectedCompany } = this.props;
        this.props.fetchById(id, Entities.REVIEW, selectedCompany);
    }

    componentWillReceiveProps(nextProps) {
        const { review } = nextProps;
        if (review.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return !this.childForm.pristine;
    }

    render() {
        const { review, t } = this.props;

        const finishFetching = this.state.startedFetching && !review.isFetching;
        if (finishFetching) {
            if (review.error) return <ErrorMessage object={review} />;

            const initialValues = { ...review.content };

            return (
                <div>
                    <RatingAnswerForm
                        t={t}
                        review={review.content}
                        onSubmit={this.handleSubmit}
                        initialValues={initialValues}
                        selectedSliderIndex={this.props.selectedSliderIndex}
                        setSliderIndex={this.props.setSliderIndex}
                        ref={ref => (this.childForm = ref)}
                    />
                </div>
            );
        } else return <CircularProgress />;
    }

    handleSubmit(data) {
        const { selectedCompany, handleClose, review } = this.props;
        // format registration date for backend
        review.content.operatorAnswer = data.operatorAnswer;
        this.props.patchOperatorAnswer(selectedCompany, review, Entities.BUS, selectedCompany);

        handleClose();
    }
}

const mapStateToProps = state => {
    console.log(state, 'state');
    return {
        review: getUpdatedEntity(state, Entities.REVIEW, state.selectedCompany),
        selectedCompany: state.selectedCompany,
        selectedSliderIndex: state.selectedSliderIndex,
    };
};

export default connect(
    mapStateToProps,
    {
        fetchById,
        patchOperatorAnswer,
        setSliderIndex,
    },
    null,
    { withRef: true },
)(RatingAnswerModal);
