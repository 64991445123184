import { AUTHENTICATION_TOKEN_HEADER, AUTHENTICATION_TOKEN_STORAGE_KEY, countries } from 'src/common/index';
import HttpStatus from 'http-status-codes';
import fetch from 'isomorphic-fetch';
import _ from 'lodash';
import AutoComplete from 'material-ui/AutoComplete';
import Checkbox from 'material-ui/Checkbox';
import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';
import Slider from 'material-ui/Slider';
import TextField from 'material-ui/TextField';
import Toggle from 'material-ui/Toggle';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import RichEditor from 'src/components/admin/help/rich-editor';
import Avatar from 'src/components/images/avatar';
import Gallery from 'src/components/images/gallery';
import { FINDER_REGIONS, LANGUAGES } from 'src/i18n';
import { Gender } from 'src/utils/constants';
import { iconFormatter } from 'src/utils/formatters';
import fontAwesomeIcons from 'src/utils/icons';

export const renderInput = field => {
    return (
        <TextField
            {...field.input}
            type={field.type || 'text'}
            multiLine={field.multiline}
            rows={field.rows}
            rowsMax={field.rowsMax}
            title={field.title}
            fullWidth={true}
            step={field.step}
            disabled={field.disabled}
            hintText={field.hintText ? field.hintText : field.label ? field.label.replace(' *', '') : undefined}
            floatingLabelText={field.label}
            errorText={field.meta.touched && field.meta.error ? field.meta.error : ''}
            inputStyle={field.style}
        />
    );
};

export const renderRichTextEditor = field => {
    return (
        <RichEditor
            {...field.input}
            isCreate={field.isCreate}
            placeholder={field.placeholder}
            noLinks={field.noLinks}
            t={field.t}
            disabled={field.disabled}
        />
    );
};

export const renderImageSelector = field => {
    return (
        <div id={field.type + '-fileinput'}>
            <Avatar
                handleCroppedImage={documents => {
                    if (field.input.onChange) {
                        if (documents) field.input.onChange(documents[0]);
                        else field.input.onChange(null);
                    }
                }}
                type={field.type}
                disabled={field.disabled}
                t={field.t}
                {...field.input}
            />
        </div>
    );
};

export const renderGalleryImages = field => {
    return (
        <div>
            <Gallery
                multiple={true}
                handleGalleryImages={documents => {
                    if (field.input.onChange) field.input.onChange(documents);
                }}
                type={field.type}
                t={field.t}
                {...field.input}
            />
        </div>
    );
};

export const renderSelectField = field => {
    return (
        <SelectField
            value={field.input.value}
            onChange={(event, index, value) => {
                if (field.input.onChange) field.input.onChange(value);
                if (field.customOnChangeCallback) field.customOnChangeCallback(field.input.name, value);
            }}
            disabled={field.disabled === true}
            floatingLabelText={field.label}
            fullWidth={true}
            errorText={field.meta.touched && field.meta.error ? field.meta.error : ''}
            multiple={field.multiple ? field.multiple : false}
            selectionRenderer={field.selectionRenderer ? field.selectionRenderer : undefined}
            floatingLabelFixed={true}>
            {field.children}
        </SelectField>
    );
};

export const renderSlider = field => {
    if (field.input.value.length >= 0) return null;

    console.log('myfield', field);
    const label = field.labelValueFormat
        ? field.t(field.label, { value: field.labelValueFormat(field.t, field.input.value) })
        : field.t(field.input.value);

    return (
        <div>
            <label>{label}</label>
            <Slider
                min={field.min}
                max={field.max}
                step={field.step}
                sliderStyle={field.sliderStyle ? field.sliderStyle : {}}
                defaultValue={field.input.value}
                value={field.input.value}
                onChange={(event, value) => {
                    if (field.input.onChange) field.input.onChange(value);
                }}
                disabled={field.disabled}
            />
            <span className="error">{field.meta.error && field.meta.error}</span>
        </div>
    );
};

export const renderToggle = field => {
    return (
        <div style={{ height: '72px', display: 'flex', alignItems: 'center' }}>
            <Toggle
                labelPosition={field.labelPosition}
                label={field.label}
                toggled={Boolean(field.input.value)}
                onToggle={(event, isInputChecked) => {
                    if (field.input.onChange) field.input.onChange(isInputChecked);
                }}
            />
        </div>
    );
};

export const genderMenuItems = t => [
    <MenuItem key={-1} value={null} primaryText="-" />,
    <MenuItem key={0} value={Gender.FEMALE} primaryText={t('gender.female')} />,
    <MenuItem key={1} value={Gender.MALE} primaryText={t('gender.male')} />,
    <MenuItem key={2} value={Gender.OTHER} primaryText={t('gender.other')} />,
];

export const nullableBooleanMenuItems = t => [
    <MenuItem key={-1} value={null} primaryText="-" />,
    <MenuItem key={0} value={false} primaryText={t('false')} />,
    <MenuItem key={1} value={true} primaryText={t('true')} />,
];

export const languageMenuItems = () =>
    LANGUAGES.map(language => {
        return <MenuItem key={language.code} value={language.code} primaryText={language.label} />;
    });

// TODO: BUF-1135: update menu items when switching language
export const countryMenuItems = () => {
    const sortCountriesFirst = ['', 'DE', 'AT']; // this is in reverse order from the top
    const countriesWithEmpty = countries.de.concat({ label: '-', value: '' });
    const sortedCountries = _.sortBy(countriesWithEmpty, [
        country => -sortCountriesFirst.indexOf(country.value),
        country => _.deburr(country.label),
    ]);

    return sortedCountries.map((country, index) => (
        <MenuItem key={index} value={country.value} primaryText={country.label} />
    ));
};

// TODO: BUF-1135: update region items when switching language
export const supportedRegionItems = () => {
    return FINDER_REGIONS.map(region => {
        const label = countries.de.find(country => country.value === region).label;
        return <MenuItem key={region} value={region} primaryText={label} />;
    });
};

// TODO: BUF-1135: update menu items when switching language
export const countryMenuItemsForCalculation = () => {
    const sortedCountries = _.sortBy(countries.de, [country => _.deburr(country.label)]);

    return sortedCountries.map((country, index) => (
        <MenuItem key={index} value={country.value} primaryText={country.label} />
    ));
};

export const iconMenuItems = () =>
    fontAwesomeIcons.map((icon, index) => {
        return <MenuItem key={index} value={icon} primaryText={iconFormatter(icon, icon)} />;
    });

export const renderCheckbox = field => {
    return (
        <Checkbox
            label={field.label}
            checked={field.input.value || false}
            onCheck={(event, value) => {
                if (field.input.onChange) field.input.onChange(value);
                if (field.customOnCheckCallback) field.customOnCheckCallback(value);
            }}
            disabled={field.disabled}
            title={field.title}
        />
    );
};

export const renderMultiSelectCheckboxes = field => (
    <div className="row">
        {field.items.map((item, index) => {
            const label = field.iconProperty ? iconFormatter(item[field.iconProperty], item.name) : item.name;

            return (
                <div key={index} className="col-md-4">
                    <Checkbox
                        label={label}
                        checked={field.input.value.includes(item._links.self.href)}
                        onCheck={(event, checked) => {
                            const currentValues = [].concat(field.input.value);
                            if (checked) currentValues.push(item._links.self.href);
                            else {
                                const itemIndex = currentValues.findIndex(element => element === item._links.self.href);
                                currentValues.splice(itemIndex, 1);
                            }
                            field.input.onChange(currentValues);
                        }}
                    />
                </div>
            );
        })}
    </div>
);

export const validatePercentage = value => {
    const parsedValue = parseFloat(value);
    return !(isNaN(parsedValue) || parsedValue < 0 || parsedValue > 100);
};

export const validateJson = value => {
    try {
        const json = JSON.parse(value);
        return typeof json === 'object';
    } catch (e) {
        return false;
    }
};

export const validateLocation = value => {
    return value && (!value.address || !value.lat || !value.lng);
};

export class Location extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            dataSource: [],
            autoCompleteService: new google.maps.places.AutocompleteService(),
            geoCoder: new google.maps.Geocoder(),
        };
    }

    componentWillReceiveProps(nextProps) {
        if (!_.isEqual(nextProps, this.props) && nextProps.meta.visited === true) this.populateDataSource(nextProps);
    }

    render() {
        const field = this.props;
        const currentSearchString = field.input.value.address ? field.input.value.address : field.input.value;

        return (
            <AutoComplete
                {...field.input}
                searchText={currentSearchString}
                fullWidth={true}
                floatingLabelText={field.label}
                filter={AutoComplete.noFilter}
                errorText={field.meta.touched && field.meta.error}
                dataSource={this.state.dataSource}
                openOnFocus={true}
                onUpdateInput={value => {
                    field.input.onChange(value);
                }}
                onNewRequest={suggest => this.populateLocationDetails(suggest, field)}
            />
        );
    }

    populateDataSource(field) {
        // if input is available check google places api for results
        let inputString;

        if (field.input.value) {
            if (field.input.value.address) inputString = field.input.value.address;
            else inputString = field.input.value;
        }

        if (inputString && inputString.length > 0) {
            const autoCompletionRequest = {
                input: inputString,
            };

            this.state.autoCompleteService.getPlacePredictions(autoCompletionRequest, (res, status) => {
                let suggestions = [];
                if (status === 'OK' && res && res.length > 0) {
                    suggestions = res.map(suggestion => {
                        return {
                            text: suggestion.description,
                            value: suggestion.place_id,
                        };
                    });
                }

                this.setState({ dataSource: suggestions });
            });

            // else no suggestions
        } else if (this.state.dataSource.length > 0) this.setState({ dataSource: [] });
    }

    populateLocationDetails(suggest, field) {
        this.state.geoCoder.geocode(
            suggest.value ? { placeId: suggest.value } : { address: suggest.text },
            (res, status) => {
                //console.log('geocode:', status, res);
                if (status === 'OK' && res && res.length > 0) {
                    const location = res[0].geometry.location;

                    const addressComponents = res[0].address_components;

                    const country = this.findComponentShort(addressComponents, 'country');
                    const state = this.findComponentLong(addressComponents, 'administrative_area_level_1');
                    const postCode = this.findComponentLong(addressComponents, 'postal_code');

                    // city is locality or sublocality
                    let city = this.findComponentLong(addressComponents, 'locality');
                    if (city == null) city = this.findComponentLong(addressComponents, 'sublocality');

                    // street is route or intersection
                    let street = this.findComponentLong(addressComponents, 'route');
                    if (street == null) street = this.findComponentLong(addressComponents, 'intersection');

                    // streetNumber is followed by subpremise(s): only append subpremise(s) when streetNumber is not empty
                    let streetNumber = this.findComponentLong(addressComponents, 'street_number');
                    const subpremise = this.findComponentsLong(addressComponents, 'subpremise');
                    if (streetNumber != null && streetNumber.trim() !== '' && subpremise.length > 0)
                        streetNumber = [streetNumber].concat(subpremise).join('/');

                    field.input.onChange({
                        address: res[0].formatted_address,
                        lat: location.lat(),
                        lng: location.lng(),
                        country: country,
                        state: state,
                        postCode: postCode,
                        city: city,
                        street: street,
                        streetNumber: streetNumber,
                    });
                }
            },
        );
    }
    findComponent = (addressComponents, type) => _.find(addressComponents, component => component.types.includes(type));
    findComponentShort = (addressComponents, type) => {
        const component = this.findComponent(addressComponents, type);
        return component != null ? component.short_name : null;
    };
    findComponentLong = (addressComponents, type) => {
        const component = this.findComponent(addressComponents, type);
        return component != null ? component.long_name : null;
    };
    findComponentsLong = (addressComponents, type) =>
        _.map(
            _.filter(addressComponents, component => component.types.includes(type)),
            component => component.long_name,
        );
}

export function pluralizeMinutes(t, minutes) {
    return `${minutes}\u00a0${minutes === 1 ? t('time.minute') : t('time.minutes')}`;
}

export function pluralizeHours(t, hours) {
    return `${hours}\u00a0${hours === 1 ? t('time.hour') : t('time.hours')}`;
}

export function pluralizeDays(t, days) {
    return `${days}\u00a0${days === 1 ? t('time.day') : t('time.days')}`;
}

export function formatMinutes(t, value) {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;

    if (hours >= 24) return formatHours(t, Math.floor(value / 60));

    if (hours === 0) return pluralizeMinutes(t, minutes);
    else if (minutes === 0) return pluralizeHours(t, hours);
    else return `${pluralizeHours(t, hours)} ${pluralizeMinutes(t, minutes)}`;
}

export function formatHours(t, value) {
    const days = Math.floor(value / 24);
    const hours = value % 24;
    if (days === 0) return pluralizeHours(t, hours);
    else if (hours === 0) return pluralizeDays(t, days);
    else return `${pluralizeDays(t, days)} ${pluralizeHours(t, hours)}`;
}

//reject if there is something found and has a different id
export const asyncCheckDuplicate = (t, url, id, field, msg) => {
    return new Promise((resolve, reject) => {
        return fetch(url, {
            credentials: 'include',
            headers: { [AUTHENTICATION_TOKEN_HEADER]: localStorage.getItem(AUTHENTICATION_TOKEN_STORAGE_KEY) || '' },
        })
            .then(response => {
                if (response.status === HttpStatus.NOT_FOUND)
                    // nothing found -> ok
                    resolve();
                else if (response.status === HttpStatus.OK || response.status === HttpStatus.NO_CONTENT)
                    return response.json();
                else throw { [field]: t('error_hint.error_validating') };
            })
            .then(json => {
                if (id === json.id)
                    // same object -> ok
                    resolve();
                else throw { [field]: msg || t('error_hint.name_already_exists') };
            })
            .catch(error => reject(error));
    });
};

//reject if the method returns CONFLICT
export const asyncCheckConflict = (t, url, field, msg) => {
    return new Promise((resolve, reject) => {
        return fetch(url, {
            credentials: 'include',
            headers: { [AUTHENTICATION_TOKEN_HEADER]: localStorage.getItem(AUTHENTICATION_TOKEN_STORAGE_KEY) || '' },
        })
            .then(response => {
                if (response.status === HttpStatus.OK || response.status === HttpStatus.NO_CONTENT)
                    // ok
                    resolve();
                else if (response.status === HttpStatus.CONFLICT) {
                    // conflict
                    throw { [field]: msg || t('error_hint.name_already_exists') };
                } else throw { [field]: t('error_hint.error_validating') };
            })
            .catch(error => reject(error));
    });
};
