import React, { Component } from 'react';
import autoBind from 'react-autobind/src/autoBind';
import TableHelper from 'src/utils/table-helper';
import { withTranslation } from 'react-i18next';
import composeModalActions from 'src/utils/modal-action-wrapper';
import ErrorMessage from 'src/components/misc/error-message';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import AdminRatingReviewModal from 'src/components/admin/rating-reviews/components/admin-rating-review-modal';
import { Entities } from 'src/common/index';

export const reviewAdministrationFilterMappings = t => ({
    ALL_REVIEWS: {
        filter: 'ALL_REVIEWS',
        title: t('colors.own_tasks'),
    },
    commentsRequireReview: {
        filter: 'REVIEW_NEEDS_APPROVAL',
        title: t('colors.own_tasks'),
    },
    answersRequireReview: {
        filter: 'ANSWER_NEEDS_APPROVAL',
        title: t('colors.own_tasks'),
    },
});

class RatingReviewAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        const query = props.location.query;
        const { t } = props;

        const currentFilterMapping =
            query && query.filter
                ? reviewAdministrationFilterMappings(t)[query.filter]
                : reviewAdministrationFilterMappings(t).ALL_REVIEWS;

        this.tableHelper = new TableHelper();
        this.state = {
            tableData: [],
            filter: currentFilterMapping.filter,
            tableTitle: currentFilterMapping.title,
            startedFetching: false,
        };
    }

    componentWillMount() {
        this.props.fetchReviewsByFilterAdmin(this.state.filter);
        this.props.fetchAll(Entities.REVIEW_CATEGORY);
    }

    componentWillReceiveProps(nextProps) {
        const { reviews, reviewCategories, selectedCompany } = nextProps;
        const query = nextProps.location.query;

        const { t } = this.props;
        const currentFilterMapping =
            query && query.filter
                ? reviewAdministrationFilterMappings(t)[query.filter]
                : reviewAdministrationFilterMappings(t).ALL_REVIEWS;

        if (currentFilterMapping.filter !== this.state.filter) {
            this.setState({
                filter: currentFilterMapping.filter,
                tableTitle: currentFilterMapping.title,
            });
            this.props.fetchReviewsByFilterAdmin(currentFilterMapping.filter);
        }
        if (reviews.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });

        switch (reviews.action.type) {
            case 'UPDATE': {
                this.setState({ tableData: this.populateTableData(reviews) });
                break;
            }
            case 'FETCH': {
                this.setState({ tableData: this.populateTableData(reviews) });
            }
        }

        this.tableHelper.processPagination(reviews.items.length);
    }

    populateTableData(reviews) {
        return reviews.items
            .filter(review => {
                switch (this.state.filter) {
                    case 'ANSWER_NEEDS_APPROVAL':
                        return review.answerStatus === 'SUBMITTED';
                    case 'REVIEW_NEEDS_APPROVAL':
                        return review.visible === false;
                    default:
                        return true;
                }
            })
            .map(review => {
                return {
                    id: review.id,
                    comment: review.comment,
                    task: review.task,
                    customer: review.customer,
                    visible: review.visible,
                    answerStatus: review.answerStatus === 'SUBMITTED' || review.answerStatus === 'REVIEWED',
                };
            });
    }

    render() {
        const { reviews, t } = this.props;
        const tableHeader =
            this.state.filter === 'REVIEW_NEEDS_APPROVAL'
                ? t('administration.task_ratings.approve_comments')
                : t('administration.task_ratings.approve_answer');
        return (
            <div>
                <legend className="legend">{tableHeader}</legend>
                {(() => {
                    if (reviews.error) return <ErrorMessage object={reviews} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={this.state.tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    dataAlign="left"
                                    width="10"
                                    dataSort={true}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="comment"
                                    dataAlign="left"
                                    width="100"
                                    dataSort={true}
                                    export={true}>
                                    {t('administration.task_ratings.comment')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="visible"
                                    dataAlign="center"
                                    width="20"
                                    dataSort={true}
                                    dataFormat={this.getReviewVisibilityFormat}
                                    export={true}>
                                    {t('administration.task_ratings.visible')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="answerStatus"
                                    dataAlign="center"
                                    width="20"
                                    dataSort={true}
                                    dataFormat={this.getAnsweredVisibilityFormat}
                                    export={true}>
                                    {t('administration.task_ratings.answered')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="30"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    getAnsweredVisibilityFormat(cell) {
        const { t } = this.props;
        return cell === true ? (
            <span
                title={t('administration.task_ratings.answered')}
                className="glyphicon glyphicon-ok-circle text-success"
            />
        ) : (
            <span
                title={t('administration.task_ratings.unanswered')}
                className="fa fa-times-circle-o text-danger fa-lg"
            />
        );
    }

    getReviewVisibilityFormat(cell, row) {
        const { t } = this.props;
        return cell === true ? (
            <span
                title={t('administration.task_ratings.visible')}
                className="glyphicon glyphicon-ok-circle text-success"
            />
        ) : (
            <span
                title={t('administration.task_ratings.under_review')}
                className="fa fa-times-circle-o text-danger fa-lg"
            />
        );
    }

    openEditModal(review) {
        const { t } = this.props;
        this.props.openModal({
            component: AdminRatingReviewModal,
            componentProps: {
                id: review.id,
                review: review,
                reviewCategories: this.props.reviewCategories,
                filter: this.state.filter,
            },
            title: 'Review',
            successButtonLabel: t('administration.task_ratings.approve_rating'),
            cancelButtonLabel: t('common_phrases.abort'),
        });
    }

    tableActionsFormatter(cell, review) {
        const { t } = this.props;
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.edit')}
                    onClick={() => {
                        this.openEditModal(review);
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
            </div>
        );
    }
}

export default withTranslation()(composeModalActions(RatingReviewAdministration));
